// @ts-nocheck
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AiOutlineSync,
  AiOutlineLock,
  AiOutlineWallet,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdMenu, MdOutlineKeyboardArrowRight } from "react-icons/md";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { formatPhone, formatMoney, formatImage } from "../utils/phoneRegex";

import "../asset/css/main.css";
import logo from "../asset/image/logo.jpg";
import PointLogo from "../asset/image/point-image.png";

import { getProfileApi } from "../api/profileApi";
import { claimPromotion, isPromoMaintain } from "../api/promotionApi";
import { toastError, toastSuccess } from "../utils/toast";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imageError } from "../utils/errors";
import { useLottery } from "../hooks/useLanguage";

function Navbar({ userData, isPrefix }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!userData?.token;
  const { t } = useTranslation();

  const { isLotteryOpen, onLotteryOpen, onLotteryClose } = useLottery();

  const [walletView, setWalletView] = useState(false);
  const [ploading, setPloading] = useState(false);
  const [promoMaintain, setPromoMaintain] = useState("1");
  const [point, setPoint] = useState(userData?.point || 0);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [p_balance, setPBalance] = useState(userData?.p_balance || 0);
  const [name, setName] = useState(userData?.name || undefined);
  const [avatar, setAvatar] = useState(formatImage(userData?.image));

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!isLoggedIn) return;
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) {
              setAmount(res?.data?.balance);
              setPoint(res?.data?.point);
              setPBalance(res?.data?.p_balance);
              setName(res?.data?.name);
              setAvatar(formatImage(userData?.image));
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);
    setPBalance(userData?.p_balance || 0);
    setName(userData?.name || undefined);
    setAvatar(formatImage(userData?.image));

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    userData?.balance,
    userData?.token,
    userData?.point,
    userData?.p_balance,
    userData?.name,
    userData?.image,
    isLoggedIn,
  ]);

  useEffect(() => {
    isPromoMaintain().then((res) => setPromoMaintain(res.is_maintain));
  }, []);

  const handlePromoClaim = useCallback(async () => {
    if (promoMaintain === "1") return;

    setPloading(true);
    try {
      await claimPromotion({ token: userData?.token })
        .then((res) => toastSuccess(res.message))
        .finally(() => setPloading(false));
    } catch (error) {
      const err = await error.json();
      toastError(err.message);
      setPloading(false);
    }
  }, [promoMaintain, userData?.token]);

  const phone = isLoggedIn && formatPhone(userData?.phone);

  const toggleSync = useCallback(async () => {
    if (!isLoggedIn || ploading) return;

    const promise = new Promise((resolve) => {
      setPloading(true);
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    promise.then(async () => {
      await getProfileApi({ token: userData?.token })
        .then((res) => {
          if (res?.status === 200) {
            setAmount(res?.data?.balance);
            setPoint(res?.data?.point);
            setPBalance(res?.data?.p_balance);
            setName(res?.data?.name);
            setAvatar(formatImage(res?.data?.image));
          }
        })
        .catch(() => {
          return toastError("Something went wrong!");
        })
        .finally(() => setPloading(false));
    });
  }, [isLoggedIn, userData?.token, ploading]);

  const toggleViewWallet = useCallback(() => {
    setWalletView((prev) => !prev);
  }, []);

  const toggleLotteryModal = useCallback(() => {
    if (isLotteryOpen) {
      return onLotteryClose();
    }

    return onLotteryOpen();
  }, [isLotteryOpen, onLotteryClose, onLotteryOpen]);

  const isShowWallet = useMemo(() => {
    if (!walletView) {
      return "xxxx";
    }
    return formatMoney(amount || 0);
  }, [amount, walletView]);

  return (
    <nav className={clsx("container shadow")}>
      <section
        id="navbar"
        className={clsx(
          "d-flex flex-row-reverse justify-content-between align-items-center text-white",
          isPrefix && "py-3"
        )}
      >
        {!isPrefix ? (
          <div
            role="button"
            style={{ width: "4.5rem" }}
            onClick={() => navigate("/")}
          >
            <img alt="logo" className="w-100" src={logo} />
          </div>
        ) : (
          <div className="position-relative w-100 d-flex align-items-center justify-content-between">
            <FaArrowLeft
              size={25}
              role="button"
              onClick={() => navigate(-1)}
              className="position-absolute mb-1"
            />
            <div
              style={{ fontSize: "1.1rem" }}
              className="text-center w-100 fw-bold text-capitalize"
            >
              {t(
                location.pathname
                  .toLocaleLowerCase()
                  .substring(1, location.pathname.length)
              )}
            </div>
          </div>
        )}
        {!isPrefix && (
          <MdMenu
            size={35}
            role="button"
            onClick={toggleLotteryModal}
            className="text-warning"
          />
        )}
      </section>

      {!isPrefix && (
        <>
          <section id="balance" className="mt-1 mb-3">
            <div className="d-flex justify-content-between align-items-center gap-4">
              <div
                style={{ width: "3.2rem" }}
                onClick={() => navigate("/profile")}
              >
                <LazyLoadImage
                  src={avatar}
                  effect="blur"
                  alt="profile"
                  onError={imageError}
                  className="img-fluid cursor-pointer rounded-circle"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "fill",
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                {isLoggedIn ? (
                  <div className="profile-name">
                    <div>{name}</div>
                    <div>{phone || userData?.phone}</div>
                  </div>
                ) : (
                  <NavLink to="/login">
                    <button className="login-btn-new">
                      <AiOutlineLock size={15} className="me-2 mb-1" />
                      အကောင့်အရင်ဝင်ပါ
                    </button>
                  </NavLink>
                )}
              </div>
              {isLoggedIn && (
                <div className="text-white text-end">
                  <AiOutlineSync
                    title="Refresh"
                    role="button"
                    disabled={ploading}
                    onClick={toggleSync}
                    className={clsx(
                      "cursor-pointer",
                      ploading && "spinner-border border-0 text-white-50"
                    )}
                    size={25}
                  />
                </div>
              )}
            </div>
          </section>

          {isLoggedIn && (
            <section id="wallet" className="my-3 text-white">
              <div className="d-flex justify-content-between align-items-center gap-5">
                <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                  <AiOutlineWallet size={25} />
                  <div className="fw-bold profile-amount">
                    {t("mainBalance")}
                  </div>
                  {walletView ? (
                    <AiOutlineEye
                      role="button"
                      onClick={toggleViewWallet}
                      size={22}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      role="button"
                      onClick={toggleViewWallet}
                      size={22}
                    />
                  )}
                  <div className="fw-bolder ms-1">
                    <span className="profile-wallet">{isShowWallet} </span>
                    <span style={{ fontSize: ".9rem" }}> 🪙</span>
                  </div>
                  <IoAddCircleOutline
                    role="button"
                    onClick={() => navigate("/cashin")}
                    size={25}
                  />
                </div>
              </div>

              <div className="profile-border my-2 mx-1" />

              <div className="d-flex justify-content-between align-items-center gap-3">
                <div
                  role="button"
                  onClick={() => navigate("/point")}
                  className="profile-point"
                >
                  <img
                    title="Point value!"
                    src={PointLogo}
                    style={{ width: "25px" }}
                    alt="point-logo"
                  />

                  <div className="fw-bolder ms-1">
                    <span className="profile-amount">
                      {formatMoney(point || 0)}{" "}
                    </span>
                    <span
                      className="text-white-50"
                      style={{ fontSize: ".6rem" }}
                    >
                      Point{point > 0 ? "s" : ""}
                    </span>
                  </div>
                  <MdOutlineKeyboardArrowRight
                    className="text-white-50"
                    size={25}
                  />
                </div>

                <button
                  disabled={ploading}
                  onClick={handlePromoClaim}
                  title="click to claim promotion award!"
                  className={clsx(
                    "btn btn-warning btn-sm rounded-1 w-50 fw-bold",
                    ploading && "disabled"
                  )}
                >
                  <span style={{ fontSize: "11.5px", letterSpacing: ".7px" }}>
                    Promotion - {p_balance}
                  </span>
                </button>
              </div>
            </section>
          )}
        </>
      )}
    </nav>
  );
}

export default Navbar;
